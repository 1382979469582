import React from 'react'
// import { Link } from "react-router-dom";
import { lookupRecord } from '../data/content';

import ContactCard from '../components/ContactCard';

export default function About() {
  const firstSection = lookupRecord('contact-page', 'line')
  const secondSection = lookupRecord('contact-page', 'write')
  const thirdSection = lookupRecord('contact-page', 'venue')

  return (

    <div className="max-w-md mx-auto text-center">



      <div className='p-10 text-white font-extrabold text-4xl'>Contact</div>


      {thirdSection.content && (
        <div className='p-3 text-white'>{thirdSection.content[0]}</div>
      )}

      <div className='mb-10'>
        <ContactCard />
      </div>

      {thirdSection.content && thirdSection.content.map((item, index) => {
        if (index > 0) {
          return (
            <div key={index} className='p-1 text-white'>{item}</div>
          )
        }
        return null
      })}


      {firstSection.content && (
        <div className='p-3 text-white'>{firstSection.content[0]}</div>
      )}
      {firstSection.content && firstSection.content.map((item, index) => {
        if (index > 0) {
          return (
            <div key={index} className='p-3 text-white'>{item}</div>
          )
        }
        return null
      })}

      {secondSection.content && (
        <div className='p-3 text-white'>{secondSection.content[0]}</div>
      )}

      {secondSection.content && secondSection.content.map((item, index) => {
        if (index > 0) {
          return (
            <div key={index} className='p-1 text-white'>{item}</div>
          )
        }
        return null
      })}


    </div>


  )
}
