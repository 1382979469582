
import productsJSON from "./data/products.json";

function getProductsArray() {

    let productsArray = [];
    if (process.env.REACT_APP_LIVE === "true") {
        productsArray = productsJSON.filter(product => product.env === 'live');
    } else {
        productsArray = productsJSON.filter(product => product.env === 'staging');
    }

    let newProductArray = productsArray.map(product => {
        return {
            "id": product.productCode,
            "title": product.content[0],
            "description": product.content,
            "dimensions": 'not used anymore',
            "price": '10',
            "images": product.images
        }
    })

    return newProductArray;
}

function getProductData(id) {
    const arr = getProductsArray();
    return arr.find(product => product.id === id);
}

export { getProductsArray, getProductData };

