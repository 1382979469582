import React, { useEffect, useContext } from "react"
import { CartContext } from "../CartContext";
import { auth } from '../firebase/firebaseConfig';
import { onAuthStateChanged } from "firebase/auth"
import { useNavigate } from "react-router-dom";

import { OrderStatus } from "../components/OrderStatus";

export default function Success() {

  // const [user, setUser] = React.useState(null);
  // const [uid, setUid] = React.useState(null);

  const cart = useContext(CartContext);
  const navigate = useNavigate();



  useEffect(() => {


    onAuthStateChanged(auth, (user) => {
      if (user) {

        // setUser(user.email)
        // setUid(user.uid)

        const userObject = {
          email: user.email,
          uid: user.uid
        }
        cart.saveUserDetails(userObject)
      }
    });

    // clear the basket
    cart.items.forEach(item => {
      // console.log("clearing item from cart", item.id, " #of - ", item.quantity)
      const tot = item.quantity * 10
      
      window.gtag('event', 'conversion', {
        'send_to': 'AW-CONVERSION_ID_1/BADGE_ID_1',
        'value': tot,
        'currency': 'GBP',
        'transaction_id': item.id,
      });
      
      cart.deleteFromCart(item.id)
    })

    // console.log("landed in success page")

  }, []);


  return (

    <div className="text-center">
      <OrderStatus />
      <button className="btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-white font-bold py-2 px-4 rounded-full btn btn-outline  text-white mt-10" onClick={() => navigate("/")}>
        OK
      </button>
    </div>


  )
}
