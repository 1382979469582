const menuShort = [
    { id: 1, text: 'About', link: '/about' },
    { id: 2, text: 'Contact', link: '/contact' },
    { id: 4, text: 'Sign Out', link: '/signout' }
]

const menuLong = [
    { id: 1, text: 'About', link: '/about' },
    { id: 2, text: 'Contact', link: '/contact' },
    { id: 3, text: 'Members', link: '/members' },
    { id: 4, text: 'Sign Out', link: '/signout' }
]

function menuItems(orderCount) {
    if(orderCount < 1) {
        return menuShort;
    }
    else {
        return menuLong;
    }
}

export { menuItems };