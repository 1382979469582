import React, { useEffect } from "react"
import { signOut } from "firebase/auth";
import { auth } from '../firebase/firebaseConfig';
import { useNavigate } from "react-router-dom";


export default function Signout() {

  const [signedOut, setSignedOut] = React.useState(false);
  const navigate = useNavigate();

  function handleSignOut() {
    signOut(auth).then(() => {
      setSignedOut(true)
      navigate('/');
    }).catch((error) => {
      setSignedOut(false)
      console.log('error is : ', error)
    });
  }

  return (

    <>
      <div className="container text-center mt-10 mx-auto">
        <div
          onClick={() => handleSignOut()}
          className=" btn-wide bg-transparent border-4 hover:bg-blue-700 
         hover:border-opacity-10 text-white font-bold py-2 px-4 rounded-full 
         btn btn-outline  text-white mt-10">
          Sign Out
        </div>
      </div>
    </>
  )
}
