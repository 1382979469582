import { CartContext } from "../CartContext";
import { useContext } from "react";
import { getProductData } from "../productsStore";

function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);
    return (
        <div>
            {productData && (
                <>
                    <h3 className="text-black">{productData.title}</h3>
                    <p className="text-black">
                        {quantity} total
                    </p>
                    <p className="text-black">
                        {(quantity * productData.price).toFixed(2)}
                    </p>
                    <button
                        className="m-5 btn btn-sm rounded-full bg-transparent 
                        border-4 hover:text-white text-black"
                        size="sm"
                        onClick={() => cart.deleteFromCart(id)}
                    >
                        Remove
                    </button>
                    <hr></hr>
                </>
            )}
        </div>
    )
}

export default CartProduct;