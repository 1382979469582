import React from 'react'

import { lookupRecord } from '../data/content';

const stripHashCharacters = (str) => {
  return str.replace(/#/g, '')
}





export default function Members() {

  const firstSection = lookupRecord('members-page', 'members')
  const secondSection = lookupRecord('members-page', 'where')
  const thirdSection = lookupRecord('members-page', 'connection')
  const fourthSection = lookupRecord('members-page', 'venues')



  return (

    <div className="max-w-md mx-auto text-center">

      {firstSection.content && (
        <div className='p-10 text-white font-extrabold text-4xl'>{stripHashCharacters(firstSection.content[0])}</div>
      )}

      {firstSection.content && firstSection.content.map((item, index) => {
        if (index > 0) {
          return item.includes("#") ? (
            <div key={index} className='p-10 text-white font-extrabold text-4xl'>
              {stripHashCharacters(item)}
            </div>
          ) : (
            <div key={index} className="p-3 text-white">
              {item}
            </div>
          );
        }
        return null
      })}

      {secondSection.content && (
        <div className='p-10 text-white font-extrabold text-4xl'>{stripHashCharacters(secondSection.content[0])}</div>
      )}

      {secondSection.content && secondSection.content.map((item, index) => {
        if (index > 0) {
          return item.includes("#") ? (
            <div key={index} className='p-10 text-white font-extrabold text-4xl'>
              {stripHashCharacters(item)}
            </div>
          ) : (
            <div key={index} className="p-3 text-white">
              {item}
            </div>
          );
        }
        return null
      })}



      {thirdSection.content && (
        <div className='p-10 text-white font-extrabold text-4xl'>{stripHashCharacters(thirdSection.content[0])}</div>
      )}

      {thirdSection.content && thirdSection.content.map((item, index) => {
        if (index > 0) {
          return item.includes("#") ? (
            <div key={index} className='p-10 text-white font-extrabold text-4xl'>
              {stripHashCharacters(item)}
            </div>
          ) : (
            <div key={index} className="p-3 text-white">
              {item}
            </div>
          );
        }
        return null
      })}



      {fourthSection.content && (
        <div className='p-10 text-white font-extrabold text-4xl'>{stripHashCharacters(fourthSection.content[0])}</div>
      )}

      {fourthSection.content && fourthSection.content.map((item, index) => {
        if (index > 0) {
          return item.includes("#") ? (
            <div key={index} className='p-10 text-white font-extrabold text-4xl'>
              {stripHashCharacters(item)}
            </div>
          ) : (
            <div key={index} className="p-3 text-white">
              {item}
            </div>
          );
        }
        return null
      })}



    </div>


  )
}
