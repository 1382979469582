import React from 'react'
import { Link } from "react-router-dom";

export default function Cancel() {
  return (

    <div className='text-center'>

      <div className='p-20 text-white text-3xl'>Order Cancelled</div>


      <Link to={"/"} >

        <button className="btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-white font-bold py-2 px-4 rounded-full btn btn-outline  text-white mt-10" >
          OK
        </button>
      </Link>

    </div>


  )
}
