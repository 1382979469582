import React from 'react';

const Layout = (props) => {
  return (
    <div>
    <div className="h-screen">
      {props.children}
    </div>
    </div>
  );
};

export default Layout;