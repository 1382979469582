import { useState, useContext, useEffect } from 'react';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { app } from "../firebase/firebaseConfig";
import { CartContext } from "../CartContext";
import classNames from 'classnames';
import { Link, useLocation } from "react-router-dom";

import BlueW from '../images/blueW-100.png'
import { menuItems } from '../menuItems.js'
import { useAuth } from "../firebase/firebaseConfig";
import BasketModal from './BasketModal';

export default function NavbarComponent() {

  const location = useLocation();
  const cart = useContext(CartContext);
  const [showMobileMenu, setShowMobileMenu] = useState(true);
  const [showBasketModal, setShowBasketModal] = useState(false);
  const [currentUID, setCurrentUID] = useState(null)
  const [orderCount, setOrderCount] = useState(0)
  const [menu, setMenu] = useState([])

  // console.log("order count", orderCount)
  const currentUser = useAuth();
  const containerClasses = classNames('flex', 'top-0', 'justify-content-center', 'absolute', 'w-full', 'origin-top', 'animate-open-menu', 'flex-col', 'w-blue', 'text-4xl', 'min-h-screen',
    {
      'hidden': showMobileMenu
    },
    {
      'flex': showMobileMenu
    }
  );

  useEffect(() => {
    // console.log("location >>> ", location.pathname)
    window.gtag("event", "page_view", {
      // page_path: location.pathname + location.search + location.hash,
      page_path: location.pathname,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

    useEffect(() => {
      setMenu(menuItems(orderCount))
    }, [])

    useEffect(() => {
      if(orderCount > 0) {
        setMenu(menuItems(orderCount))
      }
    }, [orderCount])

  useEffect(() => {
    if (currentUser) {
      setCurrentUID(currentUser.uid)
      const ordersRef = collection(getFirestore(app), `customer_orders/${currentUser.uid}/orders`);

      // Retrieve the documents in the 'orders' collection
      getDocs(ordersRef)
        .then((querySnapshot) => {

          let orders = [];
          querySnapshot.forEach((doc) => {
            const orderRecord = {
              id: doc.id,
              amountTotal: doc.data().amountTotal,
              created: doc.data().created,
              currency: doc.data().currency,
              paymentIntent: doc.data().paymentIntent,
            }
            orders.push(orderRecord);
          });
          setOrderCount(orders.length)
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    }
  }, [currentUser])

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  return (
    <div>
      <header className="sticky top-0 z-10 w-blue text-white">
        <section className="mx-auto flex max-w-4xl items-center justify-between p-4">
          <Link to={"/"} >
            <span className="flex items-center  ">
              <img src={BlueW} alt="Where Do I Go Badge" />
              <div className='hidden md:block'>Where Do I Go ?</div>
            </span>
          </Link>

          {currentUser && (<div className='text-white'>
            <button onClick={cart.toggleBasket}>
              <div className='flex border-0 border-white border items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={"w-10 h-10"}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                </svg>
                <div className='ml-2'>
                  {productsCount} {"Item(s)"}
                </div>
              </div>
            </button>
          </div>
          )}



          <div>

            {currentUser && (<button
              onClick={() => { toggleMobileMenu() }}
              id="hamburger-button"
              className="relative h-8 w-8 cursor-pointer text-3xl md:hidden"
            >
              {/* &#9776; */}
              <div
                className="absolute top-4 -mt-0.5 h-1 w-8 rounded bg-white transition-all duration-500 before:absolute before:h-1 before:w-8 before:-translate-x-4 before:-translate-y-3 before:rounded before:bg-white before:transition-all before:duration-500 before:content-[''] after:absolute after:h-1 after:w-8 after:-translate-x-4 after:translate-y-3 after:rounded after:bg-white after:transition-all after:duration-500 after:content-['']"
              ></div>
            </button>
            )}

            <nav className="hidden space-x-8 text-xl md:block" aria-label="main">
              {currentUser && menu.map((item) => {
                return (
                  <Link key={item.id} to={item.link} >{item.text}</Link>
                )
              })}
            </nav>

          </div>
        </section>
        <section
          id="mobile-menu"
          className={containerClasses}
        >
          <button onClick={() => { toggleMobileMenu() }} className="text-8xl self-end px-6">
            &times;
          </button>
          <nav
            className="flex  flex-col items-center py-8"
            aria-label="mobile"
          >
            {menu.map((item) => {
              return (
                <Link
                  key={item.id}
                  to={item.link}
                  className="w-full py-6 text-center hover:opacity-90"
                  onClick={() => { toggleMobileMenu() }}
                >{item.text}</Link>
              )
            })}
          </nav>

        </section>
      </header >
      <BasketModal
        setShowBasketModal={setShowBasketModal}
        showBasketModal={showBasketModal}
        cart={cart}
        productsCount={productsCount}
      />
    </div>
  )
}
