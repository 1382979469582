import { useState } from 'react';
import { useAuth, app, venueSignup } from "../firebase/firebaseConfig";

export default function ContactModal({ toggleContactModal, contactModal }) {
  const [messageSent, setMessageSent] = useState(false)
  const currentUser = useAuth();
  const venueSignUp = () => {
    console.log('venueSignUp')
    console.log('currentUser', currentUser)
    const res = venueSignup(app, currentUser.uid)
    console.log('res', res)
    res.then((res) => {
      console.log('SUCCESS ! res', res)
      setMessageSent(true)
    })
      .catch((err) => {
        console.log('err', err)
      }
      )
  }

  return (
    <div className='text-center'>
      <input id="my-modal" type="checkbox" className="modal-toggle" checked={contactModal} onChange={e => { }} />
      <div className="modal">
        <div className="modal-box">
          <label onClick={() => toggleContactModal()} htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <div className="">
            <div className="p-5 ">
              <div className='flex flex-col border border-0 border-green-500'>
                {!messageSent && (
                  <>
                    We'll send you a link to join up as a venue.
                    <button onClick={() => venueSignUp()} className="mx-auto btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-slate-800 font-bold py-2 px-4 rounded-full btn btn-outline mt-10" >
                      Apply for Venue
                    </button>
                  </>
                )}
                {messageSent && (
                  <>
                    <div className="alert alert-success bg-green-600 shadow-lg ">
                      <div className='text-white '>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        <span className='text-xl'> join request information is on its way, thanks !</span>
                      </div>
                    </div>
                    <button onClick={() => toggleContactModal()} className="mx-auto btn-wide bg-transparent border-4 hover:bg-blue-700 hover:border-opacity-10 text-slate-800 font-bold py-2 px-4 rounded-full btn btn-outline mt-10" >
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}