import { useEffect, useState } from "react";
import { initializeApp, getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// console.log('REACT_APP_LIVE is ', process.env.REACT_APP_LIVE)

let firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
};

if (process.env.REACT_APP_LIVE === "true") {
    console.log('LIVE configured')
}

const app = initializeApp(firebaseConfig);


// if not development
if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_LIVE === "true") {

    console.log('****** production ******')

    // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
    // key is the counterpart to the secret key you set in the Firebase console.

    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(process.env.REACT_APP_capchaSiteKey),
        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true
    });
}



const localFunctions = process.env.REACT_APP_LOCAL_FUNCTIONS
console.log('>>>> localFunctions is ', localFunctions)
// console.log('>>>> localFunctions is ', localFunctions)
console.log('>>>> REACT_APP_LOCAL_FUNCTIONS is ', process.env.REACT_APP_LOCAL_FUNCTIONS)
console.log('projectID is ===> ', firebaseConfig.projectId)

if (localFunctions === 'true') {
    console.log('****** emulator running with local functions ******')
    const functions = getFunctions(getApp());
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const auth = getAuth(app);
const db = getFirestore();

// Custom Auth Hook
function useAuth() {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => setCurrentUser(user));
        return unsub;
    }, [])

    return currentUser;
}

// Custom List Order Hook
function listOrder(app, uid) {

    if (uid === undefined) {
        return null;
    }

    const getOrders = () => {
        // Get a reference to the 'orders' collection
        const ordersRef = collection(getFirestore(app), `customer_orders/${uid}/orders`);

        // Retrieve the documents in the 'orders' collection
        const res = getDocs(ordersRef)
            .then((querySnapshot) => {

                let orders = [];
                querySnapshot.forEach((doc) => {
                    const orderRecord = {
                        id: doc.id,
                        amountTotal: doc.data().amountTotal,
                        created: doc.data().created,
                        currency: doc.data().currency,
                        paymentIntent: doc.data().paymentIntent,
                    }
                    orders.push(orderRecord);
                });
                return orders;
            })
            .catch((error) => {
                console.error("Error getting documents: ", error);
                return null;
            });
        return res;
    }

    return getOrders();

    // return customerOrders;

}

// Custom Venue Signup Hook
function venueSignup(app, uid) {

    console.log('venueSignup called with uid: ', uid)
    if (uid === undefined) {
        return null;
    }

    const logVenueSignup = () => {
        // get a reference to the 'venue_signups' collection
        const venueSignupsRef = collection(getFirestore(app), `venue_signups/${uid}/requests`);

        // add a document to the 'venue_signups' collection
        const res = addDoc(venueSignupsRef, {
            id: uid,
            date: new Date(),
        })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                return docRef.id;
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                return null;
            });
        return res;
    }

    return logVenueSignup();
}


export { auth, db, app, useAuth, listOrder, venueSignup }
export default app