import { useState } from 'react';
import ContactModal from './ContactModal';

export default function ContactCard() {

    const [contactModal, setContactModal] = useState(false);
    const toggleContactModal = () => {
        console.log('toggleContactModal', contactModal)
        setContactModal(!contactModal);
    }

    return (
        <>
            <>
                <div className="p-0">
                    <button className="btn-wide bg-transparent border-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full btn btn-outline  text-white mt-10" onClick={
                        () => {
                            toggleContactModal(true)
                        }
                    }>
                        Join Up for Venues
                    </button>
                </div>
            </>
            <ContactModal
                contactModal={contactModal}
                toggleContactModal={toggleContactModal}
            />
        </>
    )
}
