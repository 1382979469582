import { createContext, useState, useEffect } from "react";
import { getProductData } from "./productsStore";

export const CartContext = createContext({
    items: [],
    getProductQuantity: () => { },
    addOneToCart: () => { },
    removeOneFromCart: () => { },
    deleteFromCart: () => { },
    getTotalCost: () => { },
    shownBasket: false,
    toggleBasket: () => { },
    user: {},
    getUserDetails: () => { },
    saveUserDetails: () => { },
});

const CART_KEY = "my-cart";

export function CartProvider({ children }) {

    const [userDetails, setUserDetails] = useState({});
    const [showBasket, setShowBasket] = useState(false);
    const [cartProducts, setCartProducts] = useState(() => {
        const savedCart = window.localStorage.getItem(CART_KEY);
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const saveCartToLocalStorage = (cart) => {
        window.localStorage.setItem(CART_KEY, JSON.stringify(cart));
    };

    useEffect(() => {
        saveCartToLocalStorage(cartProducts);
    }, [cartProducts]);

    // [ {id: 1, quantity: 2}, {id: 2, quantity: 1} ]
    function getProductQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity;

        if (quantity === undefined) {
            return 0;
        }

        return quantity;
    }

    function addOneToCart(id) {
        const quantity = getProductQuantity(id);
        if (quantity === 0) { // product is not in cart
            setCartProducts(
                [
                    ...cartProducts,
                    {
                        id: id,
                        quantity: 1
                    }
                ]
            )
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                        product.id === id                                // if condition
                            ? { ...product, quantity: product.quantity + 1 } // if statement is true
                            : product                                        // if statement is false
                )
            )
        }
    }

    function removeOneFromCart(id) {
        const quantity = getProductQuantity(id);

        if (quantity === 1) {
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                        product.id === id                                // if condition
                            ? { ...product, quantity: product.quantity - 1 } // if statement is true
                            : product                                        // if statement is false
                )
            )
        }
    }

    function deleteFromCart(id) {
        // [] if an object meets a condition, add the object to array
        // [product1, product2, product3]
        // [product1, product3]
        setCartProducts(
            cartProducts =>
                cartProducts.filter(currentProduct => {
                    return currentProduct.id !== id;
                })
        )
    }

    function getTotalCost() {
        let totalCost = 0;
        cartProducts.map((cartItem) => {
            const productData = getProductData(cartItem.id);
            // TODO : check if productData is undefined
            if (productData === undefined) {
                return 0;
            }
            
            totalCost += (productData.price * cartItem.quantity);
            return totalCost;
        });
        return totalCost;
    }

    function toggleBasket() {
        setShowBasket(!showBasket);
    }

    function getUserDetails() {
        return userDetails;
    }

    function saveUserDetails(user) {
        setUserDetails(user);
    }

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
        shownBasket: showBasket,
        toggleBasket,
        user: userDetails,
        getUserDetails,
        saveUserDetails,
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;