import data from  './data.json'


const content  = JSON.parse(JSON.stringify(data))

export const lookupRecord = (slug, key) => {
    // find record that has the slug and key in its description field
    const record = content.find(record => record.slug === slug && record.firstLine.includes(key))
    return record
}


export default content