import { BrowserRouter, Routes, Route } from "react-router-dom"

import NavbarComponent from "./components/NavbarComponent";
import SignIn from "./pages/SignIn";
import Store from "./pages/Store";
import Success from "./pages/Success"
import Signout from "./pages/Signout";
import Cancel from "./pages/Cancel"
import CartProvider from "./CartContext";
import Layout from "./components/Layout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Members from "./pages/Members";

import './App.css';

function App() {

  return (
    <div>
      <Layout>
        <CartProvider>
          <BrowserRouter>
            <NavbarComponent />
            <Routes>
              <Route path="/" element={<Store />} />
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="success" element={<Success />} />
              <Route path="cancel" element={<Cancel />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="members" element={<Members />} />
              <Route path="signout" element={<Signout />} />
            </Routes>
          </BrowserRouter>
        </CartProvider>
      </Layout>
    </div>
  );
}

export default App;
