import React, { useRef, useState, useEffect, useContext } from "react"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { format, formatDistance, subDays } from 'date-fns'
import { BsGoogle } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';


import {
  getAuth, sendSignInLinkToEmail, onAuthStateChanged, GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth"
import { auth, app } from '../firebase/firebaseConfig';
import { CartContext } from "../CartContext";

import { getProductsArray } from "../productsStore"
import ProductCard from "../components/ProductCard"
import CheckoutCard from "../components/CheckoutCard"
import { getFirestore, collection, getDocs, getDoc, doc } from "firebase/firestore";
import { lookupRecord } from '../data/content';

const devMode = process.env.REACT_APP_DEV_MODE;
let development = false;
if (devMode === 'true') {
  development = true;
}
// const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
console.log('development is : ', development)
let actionCodeUrl = 'http://localhost:3000/sign-in'
if (!development) {
  const siteURL = process.env.REACT_APP_SITE_URL
  actionCodeUrl = `${siteURL}/sign-in`
  // console.log('process.env.NODE_ENV is : ', process.env.NODE_ENV)
}
console.log('actionCodeUrl is : ', actionCodeUrl)

export default function Store() {

  const cart = useContext(CartContext);
  const itemsRef = useRef([]);
  const [formFeedback, setFormFeedback] = useState(null);
  const [productsArray, setProductsArray] = useState([]);
  const [user, setUser] = React.useState(null);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [buyMore, setBuyMore] = useState(false)
  const [currentUID, setCurrentUID] = useState(null)
  const [memberNumber, setMemberNumber] = useState(null)
  const [shortName, setShortName] = useState(null)
  const [customerOrders, setCustomerOrders] = useState([]);
  const [joinDate, setJoinDate] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const schema = yup.object().shape({
    email: yup.string().email("must be a valid email").required("email is required"),
  })
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })
  const loginSection = lookupRecord('signinpage', '')

  const onSubmit = (formData) => {
    const email = formData.email
    console.log('email: ', email);
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, {
      url: actionCodeUrl,
      handleCodeInApp: true,
    })
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        console.log('email sent successfully to : ', email);
        setShowSignupModal(true)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error code is : ', errorCode)
        console.log('error message is : ', errorMessage)
        setFormFeedback('There was an error sending the email. please try again')
      });

  }

  useEffect(() => {
    const productsArray = getProductsArray();
    setProductsArray(productsArray)
  }, [])

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user.email)
        setCurrentUID(user.uid)

        const userObject = {
          email: user.email,
          uid: user.uid
        }
        cart.saveUserDetails(userObject)
      }
    });

    // wait before setting pageLoading to false
    // setTimeout(() => {
    //   setPageLoading(false)
    // }
    //   , 1000)

    return unsubscribe;


  }, []);


  useEffect(() => {

    const getOrders = async (uid) => {

      // console.log('uid is :::: ', uid)
      // get a reference to the 'current_customers' collection
      const currentCustomersRef = doc(getFirestore(app), `current_customers/${uid}/`)

      // retrieve the document
      getDoc(currentCustomersRef)
        .then((doc) => {
          if (doc.exists()) {

            setMemberNumber(doc.data().member_number)

            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(doc.data().date.seconds);
            setJoinDate(formatDistance(d, new Date(), { addSuffix: true }))
            setShortName(doc.data().short_name)
            setUserEmail(doc.data().email)

          } else {
            // doc.data() will be undefined in this case
            console.log("No such document for current customer");
            setBuyMore(true)
          }
        })
        .catch((error) => {
          console.log("Error getting current customer document:", error);
        });

      // Get a reference to the 'orders' collection
      const ordersRef = collection(getFirestore(app), `customer_orders/${uid}/orders`);

      // Retrieve the documents in the 'orders' collection
      getDocs(ordersRef)
        .then((querySnapshot) => {

          let orders = [];
          querySnapshot.forEach((doc) => {
            const orderRecord = {
              id: doc.id,
              amountTotal: doc.data().amountTotal,
              created: doc.data().created,
              currency: doc.data().currency,
              paymentIntent: doc.data().paymentIntent,
            }
            orders.push(orderRecord);
          });
          if (orders.length > 0) {
            console.log("========================")
            console.log('we have orders .....')
            console.log("========================")
            console.log(orders)
            // setCustomerOrders(orders);
          } else {
            console.log("*************************")
            console.log('we have no orders .....')
            console.log("*************************")
            setCustomerOrders([]);
            setBuyMore(true)
          }
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    }
    if (currentUID) {
      getOrders(currentUID);
    }
  }, [currentUID])

  const toggleBuyMore = () => {
    setBuyMore(!buyMore)
  }


  const loginWithGooogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        if (credential) {
          // notifyGoogle()
          // continue using the credential object
        } else {
          // handle the case where credential is null
        }
        // The signed-in user info.
        // const user = result.user;
        // ...
      }
      )
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // notifyGoogleError();
        console.log('error : ' + errorMessage, 'code : ' + errorCode);
      });
  }


  return (
    <div>
      <header className="relative z-0 flex items-center justify-center mb-12 overflow-hidden">
        <div className="relative  z-30 p-5 rounded-xl text-center">
          <div className="item pt-5 " ref={el => itemsRef.current.push(el)}>





            <div className="flex  flex-col justify-between items-center justify-between">

              { /* SIGN IN USER */}
              {(!user) && (


                <>

                  <button
                    onClick={() => loginWithGooogle()}
                    className="btn-wide mb-10 bg-transparent border-4 
                    hover:bg-blue-700 text-white font-bold py-2 px-4 
                    rounded-full btn btn-outline text-white mt-10"
                  >
                    <BsGoogle className="mr-2" /> sign in with google
                  </button>



                  <div className="flex flex-col w-full border-opacity-50">
                    <div className="grid bg-base-300 place-items-center"></div>
                    <div className="divider text-white before:bg-white after:bg-white">OR</div>
                    <div className="grid bg-base-300 place-items-center"></div>
                  </div>





                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      type="text"
                      placeholder="enter your @ email"
                      className="mt-10 input input-bordered input-primary w-full max-w-xs"
                      id='user-email-input' {...register("email")}
                    />
                    <div className="  text-red-400 font-bold pb-3">
                      {errors.email?.message}
                    </div>
                    <button
                      className="btn-wide mb-10 bg-transparent border-4 
                    hover:bg-blue-700 text-white font-bold py-2 px-4 
                    rounded-full btn btn-outline text-white mt-10"
                    >
                      <MdEmail className="mr-2" /> enter
                    </button>
                  </form>

                  <div className="max-w-md mx-auto text-center">


                    {/* {loginSection.content && (
                      <div className='p-10 text-white font-extrabold text-4xl'>{loginSection.content[0]}</div>
                    )} */}

                    {loginSection.content && loginSection.content.map((item, index) => {
                      if (index > 0) {
                        return (
                          <div key={index} className='p-3 text-white'>{item}</div>
                        )
                      }
                      return null
                    })}

                  </div>





                </>




              )}

            </div>





          </div>


          { /* SHOP */}

          {shortName && !buyMore && (
            <>
              <h1 className="text-white font-extrabold text-4xl p-10">Your Membership</h1>


              <div className="border border-0 border-green-400 flex  justify-center">

                <div className="card w-96 bg-base-100 shadow-xl bg-slate-50">
                  <figure className="px-10 pt-10">

                    <div className="avatar ">
                      <div className="w-24 rounded-full rounded">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-39 h-39 text-slate-500">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                      </div>
                    </div>


                  </figure>
                  <div className="card-body items-center text-center">
                    <h2 className="card-title text-slate-600">Joined {joinDate}</h2>

                    {memberNumber && (
                      <>
                        <div className="text-slate-600">
                          Membership Number
                          <div className="font-bold">
                            {memberNumber}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="text-slate-600">
                      Tag
                      <div className="font-bold">
                        {shortName}
                      </div>
                    </div>
                    <div className="text-slate-600">
                      Email
                      <div className="font-bold">
                        {userEmail}
                      </div>
                    </div>
                    <div className="card-actions">

                    </div>
                  </div>
                </div>
              </div>

              {customerOrders && customerOrders
                .sort((a, b) => b.created - a.created)
                .slice(0, 1)
                .map((order) => {
                  return (
                    <div key={order.id}>
                      <h1 className="text-white font-extrabold text-4xl pt-10">Last Order</h1>
                      <h1
                        className="text-white mt-5 ">
                        {order.created}
                      </h1>
                      <h1
                        className="text-white text-sm">
                        {order.paymentIntent}
                      </h1>
                    </div>
                  )
                })}

              <button
                onClick={() => toggleBuyMore()}
                className="mb-10 bg-transparent border-4 hover:bg-blue-700 
                text-white font-bold py-2 px-4 rounded-full btn btn-outline  
                text-white mt-10"
              >
                buy again
              </button>
            </>
          )}

          {formFeedback && (
            <div
              className="text-center text-white font-bold">
              {formFeedback}
            </div>
          )}

          {buyMore && user && (
            <>
              {productsArray && productsArray.map((productData, index) => (
                <ProductCard key={index} productData={productData} />
              ))}

              {productsArray.length > 0 && (
                <CheckoutCard productData={productsArray} />
              )}
            </>
          )}


        </div>

        <div id="" className="absolute z-1 w-full h-full w-blue opacity-100"></div>
      </header >

      { /* Modal */}
      < input id="my-modal" type="checkbox" className="modal-toggle" checked={showSignupModal} onChange={e => { }
      } />
      < div className="modal" >
        <div className="modal-box">
          <h3 className="font-bold text-lg">We've sent you an email!</h3>
          <p className="py-4">check your email for a sign in link and click on it to confirm</p>
          <div className="py-4">
            if you dont see it, dont forget to check in your spam folder as it
            may have been put there by your provider
          </div>
          <div className="modal-action">
            <label
              onClick={() => { setShowSignupModal(false) }}
              htmlFor="my-modal"
              className=" border-4 hover:bg-blue-700 text-slate-700 
            font-bold py-2 px-4 rounded-full btn btn-outline   mt-10">
              Thanks!
            </label>
          </div>
        </div>
      </div >

    </div>
  )
}
