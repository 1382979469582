import { useContext } from 'react';
import { CartContext } from '../CartContext';

export default function ProductCard(props) {

    const product = props.productData
    const title = product.description[0];
    const description = product.description.slice(1);
    const cart = useContext(CartContext)
    const productQuantity = cart.getProductQuantity(product.id)

    function addOneToCart(e, product) {
        e.preventDefault()
        cart.addOneToCart(product.id)
    }

    function removeOneFromCart(e, product) {
        e.preventDefault()
        cart.removeOneFromCart(product.id)
    }

    return (
        <div className='border-0 border-lime-200 mb-10 '>
            <div className="w-64 carousel rounded-box mx-auto">
                {product.images.map((image, index) => {
                    return (
                        <div key={index} className="carousel-item w-full">
                            <img
                                src={`/wdig/${image}`}
                                className="w-full"
                                alt="Tailwind CSS Carousel component"
                            />
                        </div>
                    )
                })
                }
            </div>


            <div className="max-w-md mx-auto">
                <div
                    className="text-white font-extrabold text-4xl p-5 mt-2"
                >
                    {title}
                </div>
                {description.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="text-white pb-5"
                        >
                            {item}
                        </div>
                    )
                })}
            </div>



            <div className="text-white font-extrabold text-4xl pb-5">£{product.price}</div>

            {productQuantity > 0 ?
                <>
                    <form>
                        <div className="text-white">In Cart: {productQuantity}</div>
                        <div className='mt-0 pb-4'>
                            <button
                                className="font-mono pb-1 bg-transparent border-4
                                hover:bg-blue-700 text-white font-bold py-2
                                px-4 rounded-full btn btn-outline 
                                first-letter:text-white mt-10"
                                onClick={(e) => removeOneFromCart(e, product)}
                            >
                                {/* minus icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                </svg>

                            </button>
                            <button
                                className="ml-5 font-mono pb-1 bg-transparent 
                                border-4 hover:bg-blue-700 text-white 
                                font-bold py-2 px-4 rounded-full btn btn-outline  
                                text-white mt-10"
                                onClick={(e) => addOneToCart(e, product)}
                            >
                                {/* plus icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>

                            </button>
                        </div>
                    </form>
                </>
                :
                <div className="p-5 text-white">
                    <button
                        className="bg-transparent border-4 hover:bg-blue-700 
                        text-white font-bold py-2 px-4 rounded-full btn 
                        btn-outline  text-white mt-10"
                        onClick={() => cart.addOneToCart(product.id)}
                    >
                        Add to cart
                    </button>
                </div>
            }
        </div>
    )
}
