import React, { useContext, useEffect, useState } from "react"
import { CartContext } from "../CartContext";
import { app, useAuth } from '../firebase/firebaseConfig';
import { getFirestore, collection, getDocs } from "firebase/firestore";


export const OrderStatus = () => {

    const cart = useContext(CartContext);
    const currentUser = useAuth();

    const [customerOrders, setCustomerOrders] = useState([]);

    const getListOfOrdersFromFirebase = (uid) => {
        const getOrders = async () => {
            // Get a reference to the 'orders' collection
            const ordersRef = collection(getFirestore(app), `customer_orders/${uid}/orders`);
            // Retrieve the documents in the 'orders' collection
            getDocs(ordersRef)
                .then((querySnapshot) => {

                    let orders = [];
                    querySnapshot.forEach((doc) => {
                        // doc.data() is an object containing the document's data
                        const orderRecord = {
                            id: doc.id,
                            amountTotal: doc.data().amountTotal,
                            created: doc.data().created,
                            currency: doc.data().currency,
                            paymentIntent: doc.data().paymentIntent,
                        }
                        orders.push(orderRecord);
                    });
                    setCustomerOrders(orders);
                })
                .catch((error) => {
                    console.error("Error getting documents: ", error);
                });
        }

        getOrders();

    }

    useEffect(() => {
        getListOfOrdersFromFirebase(cart.user.uid);
    }, []);

    return (
        <>
            {/* center the text */}
            <div className="text-center text-white">
                <h1 >Most recent order</h1>

                {currentUser && currentUser.email && currentUser.uid && (
                    <>
                        <p>{currentUser.email}</p>
                        <p>{currentUser.uid}</p>
                    </>
                )
                }

            </div>


            <div className="text-center text-white">
                <h2>Order History</h2>
                <ul>
                    {customerOrders && customerOrders
                        .sort((a, b) => b.id - a.id)
                        .slice(0, 1)
                        .map((order) => (
                            <li key={order.id}>
                                <p>Order ID: {order.id}</p>
                                <p>Amount: {order.amountTotal}</p>
                                <p>Currency: {order.currency}</p>
                                <p>Created: {order.created}</p>
                                <p>Payment Intent: {order.paymentIntent}</p>
                            </li>
                        ))}
                </ul>
            </div>

        </>
    )
}
