import React from 'react'
// import { Link } from "react-router-dom";
import { lookupRecord } from '../data/content';

export default function About() {

  const firstSection = lookupRecord('about-page', 'how')
  const secondSection = lookupRecord('about-page', 'concept')
  const thirdSection = lookupRecord('about-page', 'members')

  return (

    <div className="max-w-md mx-auto text-center">
      {firstSection.content && (
        <div className='p-10 text-white font-extrabold text-4xl'>{firstSection.content[0]}</div>
      )}

      {firstSection.content && firstSection.content.map((item, index) => {
        if (index > 0) {
          return (
            <div key={index} className='p-3 text-white'>{item}</div>
          )
        }
        return null
      })}

      {secondSection.content && (
        <div className='p-10 text-white font-extrabold text-4xl'>{secondSection.content[0]}</div>
      )}

      {secondSection.content && secondSection.content.map((item, index) => {
        if (index > 0) {
          return (
            <div key={index} className='p-3 text-white'>{item}</div>
          )
        }
        return null
      })}

      {thirdSection.content && (
        <div className='p-3 text-white '>{thirdSection.content[0]}</div>
      )}

    </div>


  )
}
